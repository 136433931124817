export const setLocalStorage = (
  key: string, initialValue: { [key: string]: string } | string): void => {
  window.localStorage.setItem(key, JSON.stringify(initialValue));
};

export const getStorage = (key: string): { [key: string]: string } | string | null => {
  const storageDataByKey = window.localStorage.getItem(key);
  if (!storageDataByKey) {
    return null;
  }
  let result = '';
  try {
    result = JSON.parse(storageDataByKey);
  } catch (e) {
    throw e;
  }
  return result;
};

export const removeStorage = (key: string): void => {
  window.localStorage.removeItem(key);
};
