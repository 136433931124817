import { ELocalStorage, EToken } from '../types/localStorageTypes';
import { getStorage, removeStorage, setLocalStorage } from '../utils/localStorageUtil';

export const setLocalStorageService = async (accessToken: string, refreshToken: string) => {
  setLocalStorage(EToken.TOKENS, {
    [ELocalStorage.AccessToken]: accessToken,
    [ELocalStorage.RefreshToken]: refreshToken,
  });
};

export const removeLocalStorageService = () => {
  removeStorage(EToken.TOKENS);
};

export const getLocalStorageService = (key: string): { [key: string]: string } | string | null => {
  try {
    return getStorage(key);
  } catch {
    removeStorage(EToken.TOKENS);
  }
  return null;
};

export const getAccessTokenLocalStorage = (): string => {
  return getLocalStorageService(EToken.TOKENS)?.[ELocalStorage.AccessToken] ?? '';
};

export const getRefreshTokenLocalStorage = (): string => {
  return getLocalStorageService(EToken.TOKENS)?.[ELocalStorage.RefreshToken] ?? '';
};
