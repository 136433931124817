import React from 'react';

export const Loader = ({ showTitle = true }: { showTitle?: boolean }) => {
  return (
    <div className="loader-wrap">
      <span className="loader"></span>
      {showTitle && <span className="title">Loading</span>}
    </div >
  );
};
