import { createSlice } from '@reduxjs/toolkit';
import { IInitialState } from '../../types/reducerTypes/maintenanceTypes';

const initialState: IInitialState = {
  status: false,
};

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setStatusOn: (state) => {
      state.status = true;
    },
    setStatusOff: (state) => {
      state.status = false;
    },
  },
});

export const {
  setStatusOn, setStatusOff,
} = maintenanceSlice.actions;

export const maintenanceReducer = maintenanceSlice.reducer;
