import { appConfig } from '../infrastructure/app-config.infrastructure';
import { AboutRoutes } from '../submodules/common/enums/routes/about-routes.enum';
import { AuthRoutes } from '../submodules/common/enums/routes/auth-routes.enum';
import { BaseRoutes } from '../submodules/common/enums/routes/base-routes.enum';
import { ChatRoutes } from '../submodules/common/enums/routes/chat-routes.enum';
import { UserRoutes } from '../submodules/common/enums/routes/user-routes.enum';

export const apiRoutes = {
  login: `${appConfig.AI_API_BASE_URL}${BaseRoutes.V1}/${AuthRoutes.BasePrefix}/${AuthRoutes.Login}`,
  recoveryUser: `${appConfig.AI_API_BASE_URL}${BaseRoutes.V1}/${UserRoutes.BasePrefix}/${UserRoutes.RecoverUser}`,
  refreshToken: `${appConfig.AI_API_BASE_URL}${BaseRoutes.V1}/${AuthRoutes.BasePrefix}/${AuthRoutes.RefreshToken}`,

  sendQuestion: `${appConfig.AI_API_BASE_URL}${BaseRoutes.V1}/${ChatRoutes.BasePrefix}/${ChatRoutes.SendQuestion}`,
  likeMessage: `${appConfig.AI_API_BASE_URL}${BaseRoutes.V1}/${ChatRoutes.BasePrefix}/${ChatRoutes.LikeAnswer}`,
  dislikeMessage: `${appConfig.AI_API_BASE_URL}${BaseRoutes.V1}/${ChatRoutes.BasePrefix}/${ChatRoutes.DislikeAnswer}`,

  version: `${appConfig.AI_API_BASE_URL}${BaseRoutes.V1}/${AboutRoutes.BasePrefix}/${AboutRoutes.GetBuildVersion}`,
  submitForm: `${appConfig.AI_API_BASE_URL}${BaseRoutes.V1}/${ChatRoutes.BasePrefix}`,

  chatHistory: `${appConfig.AI_API_BASE_URL}${BaseRoutes.V1}/${ChatRoutes.BasePrefix}/${ChatRoutes.GetHistory}`,
};
