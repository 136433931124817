import { useMutation, useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../routes/apiRoutes';
import { IApiResponseGenericDTO } from '../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IQuestionDTO } from '../submodules/common/interfaces/dto/chat/iquestion.interface';
import { IAnswerToQuestionDTO } from '../submodules/common/interfaces/dto/chat/ianswer-to-question.interface';
import { EQueryKey } from './types/queryTypes';
import { getHeaders, getHeadersAuthBeanParams, pathToUrl, request } from './utils/apiUtils';
import { EMethod } from './types/apiTypes';
import { EMessageType, IMessageType } from '../types/messagesTypes';
import { ICHatForm } from '../submodules/common/interfaces/dto/ichat-form/ichat-form';
import { useTranslation } from 'react-i18next';
import { IMessageDTO } from '../submodules/common/interfaces/dto/message/imessage.interface';
import { socketHistory } from './utils/apiSocketUtils';
import { ChatRoutes } from '../submodules/common/enums/routes/chat-routes.enum';
import { getAccessTokenLocalStorage } from '../services/localStorageService';


export const useSendQuestion = (
  onSuccess: (data: IApiResponseGenericDTO<IAnswerToQuestionDTO>) => void,
  onError: (error: IApiResponseGenericDTO<null>) => void
) => {
  const context =
    useMutation<IApiResponseGenericDTO<IAnswerToQuestionDTO>, IApiResponseGenericDTO<null>,
    IQuestionDTO & { location: string }>({
      mutationKey: [EQueryKey.SEND_QUESTION],
      mutationFn: async ({ message, location }) =>
        await request<IApiResponseGenericDTO<IAnswerToQuestionDTO>, IQuestionDTO>({
          url: `${apiRoutes.sendQuestion}?location=${location}`,
          method: EMethod.POST,
          headers: () => getHeaders(getHeadersAuthBeanParams()),
          data: { message },
        }),
      onSuccess,
      onError,
      cacheTime: undefined,
    });

  return context;
};

export const useLikeMessage = (onSuccess: () => void, onSettled: () => void) => {
  const { t } = useTranslation();
  const context = useMutation<IApiResponseGenericDTO<boolean>, IMessageType, { id: string | number }>({
    mutationKey: [EQueryKey.LIKE_MESSAGE],
    mutationFn: async ({ id }) =>
      await request<IApiResponseGenericDTO<boolean>>({
        url: pathToUrl(apiRoutes.likeMessage, ':messageId', id), method: EMethod.PUT,
        headers: () => getHeaders(getHeadersAuthBeanParams()),
      }),
    onSuccess,
    onSettled,
  });

  if (context.isError) {
    context.error = {
      messageType: EMessageType.ERROR,
      messages: [t('message.error_message')],
      defaultErrorText: '',
      isMarkHeader: false,
    };
  }

  return context;
};

export const useDislikeMessage = (
  onSuccess: (data: IApiResponseGenericDTO<ICHatForm>) => void, onSettled: () => void) => {
  const { t } = useTranslation();
  const context =
    useMutation<IApiResponseGenericDTO<ICHatForm>, IMessageType, { id: string | number }>({
      mutationKey: [EQueryKey.DISLIKE_MESSAGE],
      mutationFn: async ({ id }) =>
        await request<IApiResponseGenericDTO<ICHatForm>>({
          url: pathToUrl(apiRoutes.dislikeMessage, ':messageId', id), method: EMethod.PUT,
          headers: () => getHeaders(getHeadersAuthBeanParams()),
        }),
      onSuccess,
      onSettled,
    });

  if (context.isError) {
    context.error = {
      messageType: EMessageType.ERROR,
      messages: [t('message.error_message')],
      defaultErrorText: '',
      isMarkHeader: false,
    };
  }

  return context;
};

export const useSubmitForm = (
  onSuccess: (data: IApiResponseGenericDTO<IAnswerToQuestionDTO>) => void,
  onSettled: () => void,
  onError: () => void) => {
  const { t } = useTranslation();
  const myHeaders = new Headers();
  myHeaders.append('Authorization', getHeadersAuthBeanParams()['Authorization']);

  // eslint-disable-next-line max-len, @typescript-eslint/no-explicit-any
  const context = useMutation<IApiResponseGenericDTO<IAnswerToQuestionDTO>, IMessageType, { id: string | number, urlPath: string, data: any }>({
    mutationKey: [EQueryKey.SUBMIT_FORM],
    mutationFn: async ({ urlPath, data }) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await request<IApiResponseGenericDTO<IAnswerToQuestionDTO>, FormData>({
        url: `${apiRoutes.submitForm}${urlPath}`,
        method: EMethod.POST,
        headers: () => myHeaders,
        isStringify: false,
        data,
      }),
    onSuccess,
    onSettled,
    onError,
  });

  if (context.isError) {
    context.error = {
      messageType: EMessageType.ERROR,
      messages: [t('message.message_sent_failed'), t('message.please_try_again')],
      defaultErrorText: '',
    };
  }

  return context;
};

export const useChatHistory = ({ onSuccess, onError, enabled = true }) => {
  const { t } = useTranslation();
  const context = useQuery<IApiResponseGenericDTO<IMessageDTO[]>, { error: IMessageType }>({
    enabled,
    queryKey: [EQueryKey.CHAT_HISTORY],
    queryFn: async () => await request<IApiResponseGenericDTO<IMessageDTO[]>>({
      url: apiRoutes.chatHistory, method: EMethod.GET,
      headers: () => getHeaders(getHeadersAuthBeanParams()),
    }),
    onSuccess,
    onError,
    retryDelay: 0,
    retry: 0,
  });

  if (context.isError) {
    context.error.error = {
      messageType: EMessageType.ERROR,
      messages: [t('message.message_sent_failed'), t('message.please_try_again')],
      defaultErrorText: '',
    };
  }

  return context;
};

export const subscribeChatHistory = async (accessToken: string) => {
  const params: { accessToken: string } = {
    accessToken,
  };
  await new Promise((resolve) => {
    socketHistory.emit(ChatRoutes.SubscribeOnHistory,
      params,
      (data: IApiResponseGenericDTO<boolean>) => {
        resolve(data);
      });
  });
};

export const unsubscribeChatHistory = async () => {
  const accessToken = getAccessTokenLocalStorage();
  if (!accessToken) {
    return;
  }
  const params: { accessToken: string } = {
    accessToken,
  };

  return await new Promise((resolve, reject) => {
    try {
      socketHistory.emit(ChatRoutes.UnsubscribeFromHistory,
        params,
        (data: IApiResponseGenericDTO<boolean>) => {
          resolve(data);
        });
    } catch (e) {
      reject(e);
    }
  });
};

