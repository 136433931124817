import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from '../reducers/authSlice';
import { networkReducer } from '../reducers/networkSlice';
import { maintenanceReducer } from '../reducers/maintenanceSlice';
import logger from 'redux-logger';


const reducers = combineReducers(
  {
    auth: authReducer,
    network: networkReducer,
    maintenance: maintenanceReducer,
  });

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    return process.env.NODE_ENV === 'development' ?
      getDefaultMiddleware({ serializableCheck: false }).concat(logger) :
      getDefaultMiddleware({ serializableCheck: false });
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
