import { Manager, Socket } from 'socket.io-client';
import { appConfig } from '../../infrastructure/app-config.infrastructure';
import { store } from '../../redux/store';
import { setConnect, setDisconnect } from '../../redux/reducers/networkSlice';

export const apiBaseURL = appConfig.AI_API_BASE_URL;

export const getSocket = (namespace?: string): string => (`/${namespace}`);

const manager = new Manager(apiBaseURL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  transports: ['websocket'],
});

export const socketHistory = manager.socket(getSocket('chat'));
export const socket = manager.socket('/');

socket.on('connect', () => {
  const { dispatch } = store;
  dispatch(setConnect());
});

socket.on('disconnect', () => {
  const { dispatch } = store;
  dispatch(setDisconnect());
});

export const socketRemoveListener = (socket: Socket, eventName: string) => {
  socket.removeListener(eventName);
};
