import { createSlice } from '@reduxjs/toolkit';
import { IInitialState } from '../../types/reducerTypes/networkTypes';

const initialState: IInitialState = {
  disconnect: false,
};

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setDisconnect: (state) => {
      state.disconnect = true;
    },
    setConnect: (state) => {
      state.disconnect = false;
    },
  },
});

export const {
  setConnect, setDisconnect,
} = networkSlice.actions;

export const networkReducer = networkSlice.reducer;
