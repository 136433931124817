/* eslint-disable @typescript-eslint/no-explicit-any */
import { broadcastChannel } from '../App';
import { resetAuthStore } from '../redux/reducers/authSlice';
import { store } from '../redux/store';
import { EEventName } from './events';

interface IPayload {
  type: string;
}

export const broadcastChannelServices = () => {
  broadcastChannel.addEventListener('message', (e) => {
    const { data: { type } } = e;
    if (type === EEventName.LOGIN) {
      store.dispatch(resetAuthStore());
    }
  });
};

export const broadcastChannelServicePost = (payload: IPayload) => {
  broadcastChannel.postMessage(payload);
};
