export const setCookie = (cName: string, cValue: string, expDays: number) => {
  const date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = 'expires=' + date.toUTCString();
  document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/';
};

export const getCookie = (cName: string): undefined | string => {
  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie);
  const cArr = cDecoded.split('; ');
  let res: undefined | string = undefined;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

export const deleteCookie = (cName: string) => {
  setCookie(cName, '', -1);
};

export const removeNull = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, value]) => !!value)
      .map(([key, value]) => [
        key,
        value === Object(value) ? removeNull(value) : value,
      ]),
  );
};

export const encodeQueryData = (data: { location?: string, visitorId?: string }) => {
  const ret: string[] = [];
  for (const d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  }
  return ret.join('&');
};
