export interface IRequestConfig<B> {
  url: string;
  method?: EMethod;
  headers?: () => HeadersInit | undefined;
  data?: B;
  isStringify?: boolean,
}

export enum EMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum EStatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  SUCCESSES = 200,
}
