import { MessagePublisher } from '../submodules/common/enums/message/message-publisher.enum';
import { IApiResponseGenericDTO } from '../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IMessageDTO } from '../submodules/common/interfaces/dto/message/imessage.interface';
import { EHelpfulnessType, EMessageChatType, IMessageData } from '../types/messageTypes';

export const getDefaultUserMessage = (message: string, options: Partial<IMessageData>): IMessageData => {
  return {
    id: '',
    message,
    date: new Date(),
    publisher: MessagePublisher.User,
    messageChatType: EMessageChatType?.DEFAULT,
    isDefault: true,
    references: [],
    showLikesDislikes: false,
    sessionId: '',
    ...options,
  };
};
export const getDefaultAIMessage = (message: string, options: Partial<IMessageData> = {}): IMessageData => {
  return {
    id: '',
    message,
    date: new Date(),
    publisher: MessagePublisher.AI,
    messageChatType: EMessageChatType?.DEFAULT,
    isDefault: true,
    references: [],
    showLikesDislikes: false,
    ...options,
  };
};
export const getDefaultMessage = (message: string, options: Partial<IMessageData> = {}): IMessageData => {
  return {
    id: '',
    message,
    date: new Date(),
    messageChatType: EMessageChatType?.DEFAULT,
    isDefault: true,
    references: [],
    showLikesDislikes: false,
    publisher: MessagePublisher.AI,
    ...options,
  };
};


export const getErrorMessageAI = (error: IApiResponseGenericDTO<null>, message: string): IMessageData => {
  return {
    id: '',
    date: new Date(),
    publisher: MessagePublisher.AI,
    isDefault: true,
    messageChatType: EMessageChatType?.ERROR,
    message,
    references: [],
    error,
    showLikesDislikes: false,
  };
};

export const getGreetingMessage = (message: string, proposedTopics: string[]): IMessageData => {
  return {
    id: '',
    message,
    date: new Date(),
    publisher: MessagePublisher.AI,
    messageChatType: EMessageChatType?.DEFAULT,
    isDefault: false,
    references: [],
    showLikesDislikes: false,
    proposedTopics,
  };
};

export const getSessionErrorMessage = (message: string[]): IMessageData => {
  return {
    id: '',
    message,
    date: new Date(),
    publisher: MessagePublisher.AI,
    messageChatType: EMessageChatType?.SESSION_ERROR,
    isDefault: true,
    references: [],
    showLikesDislikes: false,
  };
};

export const getHelpfulnessType = (liked: boolean, disliked: boolean): EHelpfulnessType => {
  return liked ? EHelpfulnessType.LIKE : disliked ? EHelpfulnessType.DISLIKE : EHelpfulnessType.INDEFINITE;
};

export const prepareChatHistoryMessages = (data: IMessageDTO[], isDefault = true):
  [string | number, IMessageData][] => {
  const obj = {
    [MessagePublisher.AI]: (message: IMessageDTO): IMessageData => {
      return {
        ...message,
        id: message.id,
        message: message.message,
        date: new Date(message.created),
        publisher: MessagePublisher.AI,
        messageChatType: EMessageChatType?.DEFAULT,
        isDefault,
        references: [],
        helpfulnessType: getHelpfulnessType(message.liked, message.disliked),
        showLikesDislikes: message.liked || message.disliked,
      };
    },
    [MessagePublisher.User]: (message: IMessageDTO): IMessageData => {
      return {
        ...message,
        id: message.id,
        message: message.message,
        date: new Date(message.created),
        publisher: MessagePublisher.User,
        messageChatType: EMessageChatType?.DEFAULT,
        isDefault,
        references: [],
        helpfulnessType: getHelpfulnessType(message.liked, message.disliked),
      };
    },
  };

  return data.reduceRight<[string | number, IMessageData][]>((message, curr, i) => {
    const index = data.length - i - 1;
    message[index] = [index, obj[curr.messagePublisher](curr)];
    return message;
  }, []);
};
