
import { ReactElement } from 'react';
import { MessagePublisher } from '../submodules/common/enums/message/message-publisher.enum';
import { IReferenceDTO } from '../submodules/common/interfaces/dto/chat/ireference.interface';
import { IApiResponseGenericDTO } from '../submodules/common/interfaces/dto/common/iapi-response.interface';
import { ICHatForm } from '../submodules/common/interfaces/dto/ichat-form/ichat-form';
// import { IReferenceDTO } from '../submodules/common/interfaces/dto/chat/ireference.interface';
import { EPublisher } from './publisher.enum';
import { IMessageDTO } from '../submodules/common/interfaces/dto/message/imessage.interface';

export enum EMessageChatType {
  DEFAULT = 'DEFAULT',
  INFO = 'INFO',
  WARNING = 'WARNING',
  WARNING_CHAT_NOT_ALLOWED = 'WARNING_CHAT_NOT_ALLOWED',
  ERROR = 'ERROR',
  SESSION_ERROR = 'SESSION_ERROR',
  FORM_TYPE = 'FORM_TYPE',
}

export enum EHelpfulnessType {
  INDEFINITE = 'INDEFINITE',
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
}

export enum EFormStatus {
  SUBMITTED = 'SUBMITTED',
  UNSUBMITTED = 'UNSUBMITTED',
}

export interface ICHatFormData extends ICHatForm {
  statusForm: EFormStatus,
}

export interface IMessageData extends Omit<Partial<IMessageDTO>, 'id' | 'message'> {
  id: number | string,

  message: string | ReactElement[] | string[];
  publisher: MessagePublisher;
  isDefault?: boolean,
  messageChatType: EMessageChatType,

  parentId?: string | number,
  formData?: ICHatFormData,
  date?: Date
  helpfulnessType?: EHelpfulnessType,
  error?: IApiResponseGenericDTO<null>;

  references: IReferenceDTO[] | null;
  showLikesDislikes?: boolean;
  typing?: boolean,

  proposedTopics?: string[];
}

export enum ResponseHelpfulness {
  Like = 'Like',
  Dislike = 'Dislike',
  Neutral = 'Neutral'
}

export interface MessageModel {
  content: string;
  publisher: EPublisher;
  // references: IReferenceDTO[];
  date: Date
  id?: number,
  // showReferences?: boolean;
  showLikesDislikes?: boolean;
}
