export enum ChatRoutes {
  BasePrefix = "chats",
  SendQuestion = "questions/send",
  LikeAnswer = "answer/:messageId/like",
  DislikeAnswer = "answer/:messageId/dislike",
  CreateMeeting = "meeting/create",
  GetSchedule = "meeting/get-schedule",
  SendDislikeDescription = "answer/:messageId/:sessionId/dislikeDescription",
  SendForm = "answer/:sessionId/:formId/form",
  GetHistory = "history",
  SubscribeOnHistory = "historySubscribe",
  UnsubscribeFromHistory = "historyUnsubscribe"
}
