import { EMessageType, IMessageType } from '../types/messagesTypes';

export const prepareErrorMessage = (status: number | string | undefined, errorCode: string | undefined):
  IMessageType => {
  return {
    messageType: EMessageType.ERROR,
    messages: [status?.toString() ?? '', errorCode ?? ''],
  };
};

export const defaultMessageData = {
  messageData: {
    messageType: '',
    messages: [],
  },
};
