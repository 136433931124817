import { ReactElement } from 'react';

export interface IMessageData {
  messageData: IMessageType,
}

export interface IMessageType {
  messageType: string | EMessageType,
  messages: string[] | ReactElement[],
  isMarkHeader?: boolean,
  params?: { [k: string]: number },
  defaultErrorText?: string,
}

export const defaultError = {
  messageData: {
    messageType: '',
    messages: [],
  },
};

export enum EMessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export enum EMessageCommand {
  TEST_MESSAGE = '/test_message'
}
