export enum EQueryKey {
  AUTH = 'AUTH',
  REFRESH = 'REFRESH',
  SEND_QUESTION = 'SEND_QUESTION',
  LIKE_MESSAGE = 'LIKE_MESSAGE',
  DISLIKE_MESSAGE = 'DISLIKE_MESSAGE',
  VERSION = 'VERSION',
  SUBMIT_FORM = 'SUBMIT_FORM',
  CHAT_HISTORY = 'CHAT_HISTORY',
  RECOVERY_USER = 'RECOVERY_USER',
}
