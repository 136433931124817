import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import './assets/scss/index.scss';

// const _ChatPage = React.lazy(() => import('./pages/_Chat'));
const ChatPage = React.lazy(() => import('./pages/Chat'));
const VersionPage = React.lazy(() => import('./pages/Version'));

import './i18n/config';
import { Loader } from './components/Loader';
import { pageRoutes } from './routes/pageRoutes';
import { useAuth } from './hooks/useTypeSelectorHooks/useAuth';
import { useAuthServiceSetting } from './services/authService';
import { broadcastChannelServices } from './events/broadcastChannel';

import Maintenance from './pages/Maintenance';
import { useMaintenance } from './hooks/useTypeSelectorHooks/useMaintenance';
import { EStatus, socketMaintenance } from './api/utils/apiMaintenanceUtils';
import { store } from './redux/store';
import { setStatusOff, setStatusOn } from './redux/reducers/maintenanceSlice';

export const broadcastChannel = new BroadcastChannel('auth');

// ---

function WrapperChat() {
  const { initialFetching } = useAuth();

  useAuthServiceSetting();
  const isShowChatPage = initialFetching;

  return (
    <>
      {isShowChatPage ? <ChatPage /> : <Loader showTitle={false} />}
    </>
  );
}

// ---

function App() {
  const { status } = useMaintenance();
  const { dispatch } = store;

  useEffect(() => {
    broadcastChannelServices();

    socketMaintenance.open();
    socketMaintenance.on('maintenance', (data) => {
      if (data?.status === EStatus.ON) {
        dispatch(setStatusOn());
      } else {
        dispatch(setStatusOff());
      }
    });

    return () => {
      broadcastChannel.close();
      socketMaintenance.close();
    };
  }, []);

  if (status) {
    return <Maintenance />;
  }

  return (
    <div className='content'>
      <Routes>
        <Route path={pageRoutes.chat} element={<React.Suspense fallback={null}>
          <WrapperChat />
        </React.Suspense>} />
        <Route path={pageRoutes.version} element={<React.Suspense fallback={null}>
          <VersionPage />
        </React.Suspense>} />
        <Route path='*' element={<Navigate replace to={pageRoutes.notFound} />} />
      </Routes>
    </div>
  );
}

export default App;
