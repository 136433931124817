import {
  setInitialFetching, setIsLogin, setSessionId, setGreeting, setIsAuthSetting, resetAuthStore, setAuthError,
  setMessagesHistory,
} from './reducers/authSlice';
import { setConnect, setDisconnect } from './reducers/networkSlice';

export const rootActions = {
  setInitialFetching,
  setIsLogin,
  setSessionId,
  setGreeting,
  setIsAuthSetting,
  resetAuthStore,
  setAuthError,
  setMessagesHistory,

  setConnect,
  setDisconnect,
};
