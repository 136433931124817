const Maintenance = () => {
  return (
    <div className="wrapper-maintenance-page">
      <div>

        <div className="logo"><span /></div>

        <p>Sorry for the inconvenience, but we are performing some maintenance at the moment.</p>
        <p>We will be back online shortly!</p>

      </div>
    </div>
  );
};

export default Maintenance;
