export class AppConfig {
  public get AI_API_BASE_URL(): string {
    return process.env.REACT_APP_API_BASE_URL ?? '';
  }
  public get AI_VISITOR_ID(): string {
    return process.env.REACT_APP_API_VISITOR_ID ?? '';
  }
  public get AI_API_MAINTENANCE_URL(): string {
    return process.env.REACT_APP_ENDPOINT_MAINTENANCE ?? '';
  }
}

export const appConfig: AppConfig = new AppConfig();
