import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import App from './App';
import { defaultOptions } from './utils/reactQueryUtils';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const queryClient = new QueryClient({ defaultOptions });

TimeAgo.addDefaultLocale(en);

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      {
        process.env.NODE_ENV === 'development' ? (
          <BrowserRouter>
            <App />
          </BrowserRouter>
        ) : (
          <React.StrictMode>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </React.StrictMode>
        )
      }
    </Provider>
    <ReactQueryDevtools position='top-right' />
  </QueryClientProvider>
);
